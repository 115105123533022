<template>
   <v-container fluid>
    <v-app-bar :color=colorMenu flat app dense height="40px" dark>
      <v-btn icon :to="{ name: complejo}">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-toolbar-title>{{tituloHeader}}</v-toolbar-title>
       <v-btn class="ma-0 mt-2 "
          to="actividadesrdaWhatsapp"
          small
           fab          
          :color=colorMenu
          dark          
          fixed
          right 
        >
          <v-icon size="48">mdi-whatsapp</v-icon>
        </v-btn>
      <v-spacer></v-spacer> 
       <template v-slot:extension>
        <v-tabs dense
          v-model="tab"
          grow
          show-arrows
          center-active
        >
          <v-tab dense class="ma-0 pa-0" 
            v-for="(item,index) in dias"
            :key="index"
            @click="filtroPorTipo(item.valor)"
          >
            {{ item.dia }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>    
     <v-card>
       <v-card-text>
                     <v-timeline 
                     dense    
                  >
                  <v-timeline-item dense 
                    color="green"                    
                    :icon=actividad.icono 
                    v-for="(actividad,index) in datosObtenidosFiltrados" :key="index"                  >
                    <v-card dense elevation="1">
                       <v-card-text dense >
                         <strong v-html=actividad.descripcion></strong>
                       </v-card-text>
                    </v-card>
                  </v-timeline-item>
                     </v-timeline>
       </v-card-text>
     </v-card>
    <v-row>
      <v-col cols=12>
          <v-card dense class="mt-2 mb-4 mx-4 pa-2 caption text-center" elevation-1 :color=colorMenu dark v-show="pie">                      
                                <span v-html="pie"></span>                         
          </v-card>
      </v-col>      
    </v-row>
  </v-container>   
</template>
<script>

import axios from 'axios'
export default {
  name:"actividadesrda",
   props: {
          tituloHeader: String,
          idcomplejo:Number,
          complejo: String,
          colorMenu:String,
  },
  data() {
    return {
           tab:null,
           tabelegido:0,
           datosObtenidos:[],
           datosObtenidosFiltrados:[],
           pie:'Todas las actividades SON GRATUITAS y se realizan al aire libre, los torneos tienen inscripción previa!<br>Veni a divertirte y compartir con los cuidados pertinentes a los TIEMPOS DE COVID!',
           dias:[{
                   dia: 'Domingo',
                   valor: 1
                 },
                 { dia: 'Lunes',
                  valor:2},
                  {
                   dia: 'Martes',
                   valor: 3
                 },
                 {
                   dia: 'Miercoles',
                   valor: 4
                 },
                 {
                   dia: 'Jueves',
                   valor: 5
                 },
                 {
                   dia: 'Viernes',
                   valor: 6
                 }
           ]
        }
  },
    created: async function() {                
               await axios.get(process.env.VUE_APP_API +'/complejo/actividades',
                                {params:{
                                  idcomplejo: this.idcomplejo
                                    }
                              })
                          .then((response) => {
                                              this.datosObtenidos= response.data
                                          //    alert(JSON.stringify(this.datosObtenidos))
                          })
               let dia = new Date().getDay()

                if (dia==6){
                 this.filtroPorTipo(1)
               }
               else {
                  this.filtroPorTipo(dia+1) 
               }
               
  },
  methods:{
          
           filtroPorTipo(id) { 
                                this.tab=id-1
                                this.datosObtenidosFiltrados = this.datosObtenidos.filter(
                                  dia => dia.dia === id
                                );
                              },
         
          }    
 }
</script>